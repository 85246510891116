import { APIErrorResponse } from '@archax/shared-types'
import { Button, Card, Container, Divider, Link, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import MsSsoIcon from '../../../assets/ms-sso-icon.svg'
import { useGlobalContext } from '../../../context'
import onApiError from '../../../util/on-api-error'
import { login } from '../auth.service'
import { LoginForm } from '../components/login-form'
import { LoginFormData } from '../types'

function LoginPage() {
  const navigate = useNavigate()
  const {
    actions: {
      auth: { setCredentials },
    },
  } = useGlobalContext()
  const handleSubmit = async (data: LoginFormData) => {
    try {
      const credentials = await login(data)
      setCredentials(credentials)
      navigate('/')
    } catch (error) {
      onApiError(error as AxiosError<APIErrorResponse>)
    }
  }

  return (
    <Container maxWidth="sm">
      <Card sx={{ p: 7, marginTop: 20 }}>
        <LoginForm onSubmit={handleSubmit} />
        <Divider sx={{ m: 3 }}>Or</Divider>
        <Link href={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_SAML_REDIRECT_URI}`}>
          <Button type="submit" variant="contained" size="large" color="primary" fullWidth>
            <img alt="azure ad icon" src={MsSsoIcon} />
            <Typography sx={{ marginLeft: 1 }}>Enterprise login</Typography>
          </Button>
        </Link>
      </Card>
    </Container>
  )
}
export default LoginPage
