"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressBlacklistedByTokenError = void 0;
class AddressBlacklistedByTokenError extends Error {
    constructor(address, tokenId) {
        super(`The address ${address} is blacklisted by token ${tokenId}`);
        Error.captureStackTrace(this, this.constructor);
    }
}
exports.AddressBlacklistedByTokenError = AddressBlacklistedByTokenError;
