import { styled, TableCellProps, TableCell, TableRow } from '@mui/material'

export const Wrapper = styled('div')(() => ({
  width: '42rem',
  maxWidth: '42rem',
}))

export const StyledTableRow = styled(TableRow)(() => ({
  display: 'flex',
  width: '100%',
}))

export interface StyledTableCellProps extends TableCellProps {
  flex?: number;
}

export const StyledTableCell = styled(TableCell)<StyledTableCellProps>(({ flex = 1 }) => ({
  flex: flex,
}));
