import { User } from '@archax/shared-types'
import { Card, Container, Typography, styled } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { OrganizationCreateParams, editOrganization, getOrganizationById } from '../../api/organizations'
import { useGlobalContext } from '../../context'
import onApiError from '../../util/on-api-error'
import OrganizationsForm from './components/OrganizationsForm'

const StyledTitle = styled(Typography)(() => ({
  marginBottom: '1rem',
}))

interface EditOrganizationProps {
  organizationId?: string
}

function EditOrganization({ organizationId }: EditOrganizationProps) {
  const {
    state: { user },
    actions: {
      auth: { getUser },
    },
  } = useGlobalContext()

  const { mutate } = useMutation(
    (formData: OrganizationCreateParams) => editOrganization(organizationId || (user as User).organizationId, formData),
    {
      onSuccess: (data) => {
        toast.success('Organization edited successfully')
        getUser()
      },
      onError: onApiError,
    },
  )

  const { isLoading, data, isFetching } = useQuery(
    ['get-organization'],
    () => getOrganizationById(organizationId || (user as User).organizationId),
    {
      refetchOnWindowFocus: false,
    },
  )

  return (
    <Container>
      <Card sx={{ p: 7 }}>
        <StyledTitle align="left" variant="h3">
          Edit Organization
        </StyledTitle>
        {isLoading && <div>Loading...</div>}
        {!isFetching && data && (
          <OrganizationsForm
            onSubmit={mutate}
            mode="edit"
            defaultValues={{
              name: data.data.name,
              internalOrganizationId: data.data.internalOrganizationId,
              createdAt: data.data.createdAt,
              logo: data.data.logoId,
              logoHasTransparentBackground: data.data.logoHasTransparentBackground,
            }}
            organizationId={organizationId || (user as User).organizationId}
          />
        )}
      </Card>
    </Container>
  )
}

export default EditOrganization
