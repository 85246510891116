import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Operation,
  OperationStatus as OperationStatusEnum,
  OperationType,
  READABLE_OPERATION_TYPE,
} from '@archax/shared-types'
import BlockIcon from '@mui/icons-material/Block'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Button, styled, Tooltip, Typography } from '@mui/material'
import Dialog from '../../Dialog/Dialog'
import { formatDate } from '../../../util/formatters'

const Wrapper = styled('div')(() => ({
  width: '42rem',
  maxWidth: '42rem',
}))
const Content = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  overflowX: 'auto',
}))

function ErrorOperationStatus({
  type,
  approvedAt,
  error,
}: {
  type: OperationType
  approvedAt: Date
  error: any
}): ReactElement {
  const { t } = useTranslation()
  const [showDetails, setShowDetails] = useState(false)
  return (
    <div style={{ display: 'flex' }}>
      <Button onClick={() => setShowDetails(true)} variant="outlined" color="error">
        {t('token.history.status.error')}
      </Button>
      <Dialog title={''} onClose={() => setShowDetails(false)} open={showDetails}>
        <Wrapper>
          <Typography variant="h5" mb={4}>
            {READABLE_OPERATION_TYPE[type]} ({formatDate(approvedAt?.toString())})
          </Typography>
          <Content>
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </Content>
        </Wrapper>
      </Dialog>
    </div>
  )
}

export type OperationStatusProps = {
  operation: Operation
}
const OperationStatus: React.FunctionComponent<OperationStatusProps> = ({
  operation: { type, approvedAt, status, error },
}) => {
  const { t } = useTranslation()
  if (status === OperationStatusEnum.NotApproved) {
    return (
      <Tooltip title={t('token.history.status.notApproved')}>
        <BlockIcon color="error" />
      </Tooltip>
    )
  }
  if (status === OperationStatusEnum.Approved) {
    if (error) {
      return <ErrorOperationStatus approvedAt={approvedAt} type={type} error={error} />
    }

    return (
      <Tooltip title={t('token.history.status.approved')}>
        <CheckBoxIcon color="success" />
      </Tooltip>
    )
  }

  return <></>
}

export default OperationStatus
