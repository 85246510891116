"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HEDERA_TRANSACTIONS_QUEUE_PREFIX = exports.HEDERA_TRANSACTIONS_QUEUE_NAME = exports.SILENT_DATA_ROLLUP_TRANSACTIONS_QUEUE_PREFIX = exports.SILENT_DATA_ROLLUP_TRANSACTIONS_QUEUE_NAME = exports.POLYGON_TRANSACTIONS_QUEUE_PREFIX = exports.POLYGON_TRANSACTIONS_QUEUE_NAME = exports.ETHERLINK_TRANSACTIONS_QUEUE_PREFIX = exports.ETHERLINK_TRANSACTIONS_QUEUE_NAME = exports.ETHEREUM_TRANSACTIONS_QUEUE_PREFIX = exports.ETHEREUM_TRANSACTIONS_QUEUE_NAME = exports.ARBITRUM_TRANSACTIONS_QUEUE_PREFIX = exports.ARBITRUM_TRANSACTIONS_QUEUE_NAME = void 0;
exports.ARBITRUM_TRANSACTIONS_QUEUE_NAME = 'arbitrum-transactions';
exports.ARBITRUM_TRANSACTIONS_QUEUE_PREFIX = '{arb}';
exports.ETHEREUM_TRANSACTIONS_QUEUE_NAME = 'ethereum-transactions';
exports.ETHEREUM_TRANSACTIONS_QUEUE_PREFIX = '{eth}';
exports.ETHERLINK_TRANSACTIONS_QUEUE_NAME = 'etherlink-transactions';
exports.ETHERLINK_TRANSACTIONS_QUEUE_PREFIX = '{xtz}';
exports.POLYGON_TRANSACTIONS_QUEUE_NAME = 'polygon-transactions';
exports.POLYGON_TRANSACTIONS_QUEUE_PREFIX = '{matic}';
exports.SILENT_DATA_ROLLUP_TRANSACTIONS_QUEUE_NAME = 'silentdatarollup-transactions';
exports.SILENT_DATA_ROLLUP_TRANSACTIONS_QUEUE_PREFIX = '{sdr-eth}';
exports.HEDERA_TRANSACTIONS_QUEUE_NAME = 'hedera-transactions';
exports.HEDERA_TRANSACTIONS_QUEUE_PREFIX = '{hedera}';
