import isValidAlgorandAddress from './algorand'
import isValidEthereumAddress from './evm-address'
import { isValidHederaAddress } from './hedera'
import { isValidXRPLedgerAddress } from './xrpl'
import { ChainProtocol } from '@archax/shared-types'

export function isValidAddress(value: string, protocol: ChainProtocol) {
  switch (protocol) {
    case ChainProtocol.Algorand:
      return isValidAlgorandAddress(value)
    case ChainProtocol.Arbitrum:
    case ChainProtocol.Ethereum:
    case ChainProtocol.Etherlink:
    case ChainProtocol.Polygon:
      return isValidEthereumAddress(value)
    case ChainProtocol.Hedera:
      return isValidHederaAddress(value)
    case ChainProtocol.XRP_Ledger:
      return isValidXRPLedgerAddress(value)
    default:
      throw new Error('Invalid protocol')
  }
}
