import {
  Distribution,
  DistributionOperationData,
  DistributionStatus,
  DistributionStatusMap,
  Operation,
  OperationProcessStatus,
  OperationStatus,
  OperationType,
  READABLE_DISTRIBUTION_STATUS,
  TABLE_HEADER_NAMES,
  Token,
  UserRole,
} from '@archax/shared-types'
import CancelIcon from '@mui/icons-material/Cancel'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Card, Container, IconButton, Typography } from '@mui/material'
import { ColDef, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { cancelOperation, createOperation } from '../../api/operations'
import { getDistributionSchedule } from '../../api/tokens'
import Dialog from '../../components/Dialog/Dialog'
import { ServerSideGrid } from '../../components/ServerSideGrid'
import { useGlobalContext } from '../../context'
import { substringFilterParams } from '../../util/common-grid-options'
import { formatDate } from '../../util/formatters'
import onApiError from '../../util/on-api-error'
import { hasOneOfRoles } from '../../util/user-roles'
import DistributionForm from '../token/components/DistributionForm/DistributionForm'

const getDistributionStatus = ({ status, operationStatus }: Operation) => {
  if (status === OperationStatus.Approved && operationStatus === OperationProcessStatus.Error) {
    return DistributionStatus.Failed
  }

  if (status === OperationStatus.Approved && operationStatus === OperationProcessStatus.Success) {
    return DistributionStatus.Completed
  }

  return DistributionStatusMap[status]
}

export default function ListDistribution() {
  const [selectedRow, setSelectedRow] = useState<any>()
  const [showCancelDistributionDialog, setShowCancelDistributionDialog] = useState(false)
  const [showDistributionDialog, setShowDistributionDialog] = useState(false)
  const [selectedToken, setSelectedToken] = useState<Token | null>(null)
  const [selectedDefaults, setSelectedDefaults] = useState<DistributionOperationData | null>(null)
  const [selectedDistributionId, setSelectedDistributionId] = useState<string | null>(null)

  const {
    state: { user },
  } = useGlobalContext()

  const cancelNewOperation = () => {
    const { data, api } = selectedRow

    cancelOperation(data.operation.id)
      .then(() => {
        toast.success('Distribution request cancelled')
        api.refreshServerSide()
      })
      .catch(onApiError)
    setShowCancelDistributionDialog(false)
  }

  const createCancelOperation = () => {
    const { data, api } = selectedRow

    createOperation(OperationType.CancelDistribution, { distributionId: data.id }, data.token.id)
      .then(() => {
        toast.success('Cancel distribution request created')
        api.refreshServerSide()
      })
      .catch(onApiError)
    setShowCancelDistributionDialog(false)
  }

  const handleCancelOperation = () => {
    const { data } = selectedRow
    if (data && DistributionStatusMap[data.operation.status as OperationStatus] === DistributionStatus.New)
      return cancelNewOperation()
    else return createCancelOperation()
  }

  const columnDefs: ColDef<Distribution>[] = useMemo(
    () => [
      {
        field: 'token.name',
        headerName: TABLE_HEADER_NAMES.distribution.token_name,
        sortable: true,
        filter: true,
        filterParams: substringFilterParams,
        flex: 1,
        minWidth: 160,
      },
      {
        field: 'operation.status',
        headerName: TABLE_HEADER_NAMES.distribution.status,
        sortable: true,
        filter: true,
        filterParams: substringFilterParams,
        valueGetter: (params) => {
          return READABLE_DISTRIBUTION_STATUS[getDistributionStatus(params.data!.operation!)]
        },
      },
      {
        field: 'snapshotUnixTimestamp',
        headerName: TABLE_HEADER_NAMES.distribution.snapshot_date,
        valueGetter: (params: ValueGetterParams<Distribution>) => {
          return formatDate(dayjs.unix(params.data!.snapshotUnixTimestamp).toString())
        },
        minWidth: 140,
      },
      {
        field: 'executeAt',
        headerName: TABLE_HEADER_NAMES.distribution.distribution_date,
        valueGetter: (params: ValueGetterParams<Distribution>) => {
          const executeAtDate = params.data!.executeAt ? dayjs(params.data!.executeAt) : null
          if (executeAtDate) {
            return formatDate(executeAtDate.toString())
          }
        },
        minWidth: 140,
      },
      {
        field: 'approved_at',
        headerName: TABLE_HEADER_NAMES.distribution.approved_at,
        valueGetter: (params: ValueGetterParams<Distribution>) => {
          const approvedAt = params.data!.operation.approvedAt
          if (approvedAt) {
            return formatDate(approvedAt.toString())
          }
        },
        minWidth: 140,
      },
      {
        field: 'distributionAsset',
        headerName: TABLE_HEADER_NAMES.distribution.distribution_asset,
        sortable: true,
        filter: true,
        filterParams: substringFilterParams,
        minWidth: 200,
      },
      {
        field: 'distributionRate',
        headerName: TABLE_HEADER_NAMES.distribution.distribution_rate,
        sortable: true,
        filter: true,
        filterParams: substringFilterParams,
      },
      {
        field: 'actions',
        headerName: '',
        sortable: true,
        filter: true,
        filterParams: substringFilterParams,
        maxWidth: 82,
        cellStyle: { textAlign: 'center', padding: '0', margin: '0', display: 'flex', justifyContent: 'center' },
        cellRenderer: (params: ICellRendererParams) => {
          const isCancelEnabled =
            [OperationStatus.PendingApproval, OperationStatus.Queued].includes(params.data.operation.status) &&
            hasOneOfRoles([UserRole.User], user)

          return (
            <Box display={'flex'} alignItems={'center'}>
              <IconButton
                aria-label="info"
                color="primary"
                onClick={() => {
                  setSelectedToken(params.data.token)
                  setSelectedDefaults({
                    distributionAsset: params.data.distributionAsset,
                    distributionRate: params.data.distributionRate,
                    snapshotUnixTimestamp: params.data.snapshotUnixTimestamp,
                    executeAt: params.data.executeAt,
                  })
                  setSelectedDistributionId(params.data.id)
                  setShowDistributionDialog(true)
                }}
              >
                <InfoOutlinedIcon color="info" />
              </IconButton>
              {isCancelEnabled && (
                <IconButton
                  aria-label="cancel"
                  color="primary"
                  onClick={() => {
                    setSelectedRow({ data: params.data, api: params.api })
                    setShowCancelDistributionDialog(true)
                  }}
                >
                  <CancelIcon color="error" />
                </IconButton>
              )}
            </Box>
          )
        },
      },
    ],
    [],
  )

  const gridOptions: GridOptions<any> = useMemo(
    () => ({
      columnDefs,
      floatingFilter: true,
      defaultColDef: { flex: 1 },
      onGridReady: (params) => {
        params.api?.sizeColumnsToFit()
      },
    }),
    [columnDefs],
  )

  return (
    <Container data-testid="distribution" maxWidth="xl">
      <Card sx={{ p: 7 }}>
        <Typography data-testid="distribution__title" align="left" variant="h3">
          Distributions
        </Typography>
        <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'} justifyContent={'space-between'}>
          <Typography align="left">Select the distribution you would like to manage</Typography>
        </Box>
        <Box>
          <ServerSideGrid gridOptions={gridOptions} queryFn={getDistributionSchedule} />
        </Box>
      </Card>
      <Dialog
        open={showCancelDistributionDialog}
        onClose={() => setShowCancelDistributionDialog(false)}
        title="Cancel distribution"
        onConfirm={() => {
          handleCancelOperation()
        }}
        showCancel={true}
        confirmLabel="Yes"
      >
        <Typography>Are you sure you want to cancel the distribution?</Typography>
      </Dialog>
      <Dialog title="" onClose={() => setShowDistributionDialog(false)} open={showDistributionDialog} fullWidth>
        <DistributionForm
          token={selectedToken!}
          defaults={selectedDefaults!}
          onClose={() => setShowDistributionDialog(false)}
          distributionId={selectedDistributionId!}
        />
      </Dialog>
    </Container>
  )
}
