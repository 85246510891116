import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person'
import { Stack } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { User, UserRole } from '@archax/shared-types'
import ArchaxLogo from '../../assets/logo.svg'
import { useGlobalContext } from '../../context'
import { hasOneOfRoles } from '../../util/user-roles'
import Dialog from '../Dialog/Dialog'
import UserDetailsForm, { UserDetailFormMode } from '../../feature/users/components/UserDetailsForm'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import AccountsBalances from '../AccountsBalances/AccountsBalances'
import { useTranslation } from 'react-i18next'
import { ImportToken } from '../../feature/token/components/ImportToken'

enum MenuPageTypes {
  Link = 'link',
  Menu = 'Menu',
  Button = 'Button',
}

interface Page {
  title: string
  type: MenuPageTypes
  onClick: (params?: React.MouseEvent<HTMLElement>) => void
  url?: string
  visibleFor?: UserRole[]
  customValidator?: (page: Page, user: User) => boolean
  subItems?: Page[]
  anchorEl?: HTMLElement | null
  onClose?: () => void
}

const pageIsVisible = (page: Page, user: User) => {
  if (!page.visibleFor) {
    return true
  }
  if (page.customValidator) {
    return page.customValidator(page, user) && hasOneOfRoles(page.visibleFor, user)
  }
  return hasOneOfRoles(page.visibleFor, user)
}

const isBackgroundTransparent = (user: User) => user.logoHasTransparentBackground

function Header() {
  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElTokenMenu, setAnchorElTokenMenu] = React.useState<null | HTMLElement>(null)

  const [openUserProfileDialog, setOpenUserProfileDialog] = React.useState(false)
  const [anchorElAccountsBalance, setAnchorElAccountsBalance] = React.useState<null | HTMLElement>(null)
  const [openImportTokenDialog, setOpenImportTokenDialog] = React.useState(false)

  const {
    state: { user },
  } = useGlobalContext()
  const { t } = useTranslation()

  const handleOpenTokenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTokenMenu(event.currentTarget)
  }

  const handleCloseTokenMenu = () => {
    setAnchorElTokenMenu(null)
  }

  const standardMenuItemOnClick = (url: string) => {
    handleCloseNavMenu()
    navigate(url)
  }

  const tokenSubItems: Page[] = [
    {
      title: 'Create token',
      type: MenuPageTypes.Link,
      url: '/tokens/create',
      visibleFor: [UserRole.User],
      onClick: () => {
        handleCloseTokenMenu()
        standardMenuItemOnClick('/tokens/create')
      },
    },
    {
      title: 'Token management',
      type: MenuPageTypes.Link,
      url: '/tokens',
      onClick: () => {
        handleCloseTokenMenu()
        standardMenuItemOnClick('/tokens')
      },
    },
    {
      title: 'Import token',
      type: MenuPageTypes.Button,
      visibleFor: [UserRole.User],
      onClick: () => {
        handleCloseTokenMenu()
        setOpenImportTokenDialog(true)
      },
    },
    {
      title: 'Consolidated Cap Table',
      type: MenuPageTypes.Link,
      url: '/tokens/consolidated-cap-table',
      onClick: () => {
        handleCloseTokenMenu()
        standardMenuItemOnClick('/tokens/consolidated-cap-table')
      },
    },
    {
      title: 'Distribution',
      type: MenuPageTypes.Link,
      url: '/tokens/distribution',
      onClick: () => {
        handleCloseTokenMenu()
        standardMenuItemOnClick('/tokens/distribution')
      },
    },
  ]

  const menu: Page[] = [
    {
      title: 'Tokens',
      type: MenuPageTypes.Menu,
      url: '/',
      subItems: tokenSubItems.filter((page) => pageIsVisible(page, user!)),
      onClick: (params) => {
        handleOpenTokenMenu(params!)
      },
      anchorEl: anchorElTokenMenu,
      onClose: handleCloseTokenMenu,
    },
    {
      title: 'Admin approval',
      type: MenuPageTypes.Link,
      url: '/tokens/requests',
      visibleFor: [UserRole.Approver, UserRole.Admin],
      onClick: () => standardMenuItemOnClick('/tokens/requests'),
    },
    {
      title: 'Edit requests',
      type: MenuPageTypes.Link,
      url: '/tokens/requests/edit',
      visibleFor: [UserRole.User, UserRole.Admin],
      onClick: () => standardMenuItemOnClick('/tokens/requests/edit'),
    },
    {
      title: 'holder_other',
      type: MenuPageTypes.Link,
      url: '/holders',
      onClick: () => standardMenuItemOnClick('/holders'),
    },
    {
      title: 'Users',
      type: MenuPageTypes.Link,
      url: '/users',
      visibleFor: [UserRole.Admin],
      onClick: () => standardMenuItemOnClick('/users'),
    },
    {
      title: 'Organizations',
      type: MenuPageTypes.Link,
      url: '/organizations',
      visibleFor: [UserRole.Admin],
      customValidator: (page: Page, user: User) => {
        return user.organization.isAdmin
      },
      onClick: () => standardMenuItemOnClick('/organizations'),
    },
    {
      title: 'Organization',
      type: MenuPageTypes.Link,
      url: '/organization',
      visibleFor: [UserRole.Admin],
      customValidator: (page: Page, user: User) => {
        return !user.organization.isAdmin
      },
      onClick: () => standardMenuItemOnClick('/organization'),
    },
    {
      title: 'Standards',
      type: MenuPageTypes.Link,
      url: '/standards',
      onClick: () => standardMenuItemOnClick('/standards'),
      visibleFor: [UserRole.User, UserRole.Admin],
      customValidator: (page: Page, user: User) => {
        return user?.organization.isAdmin
      },
    },
  ]

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleOpenAccountsBalance = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccountsBalance(event.currentTarget)
  }

  const handleCloseAccountsBalance = () => {
    setAnchorElAccountsBalance(null)
  }

  const {
    actions: {
      auth: { logout },
    },
  } = useGlobalContext()

  const getLogoSrc = (user: User) => {
    if (user.logoId) return `/api/organizations/${user.organizationId}/logo/${user.logoId}`
    return ArchaxLogo
  }
  const handleLogout = () => {
    logout()
    navigate('/login')
  }
  if (!user) {
    return <></>
  }
  const userMenu = menu.filter((page) => pageIsVisible(page, user))

  return (
    <AppBar data-testid="app-bar" color="transparent" position="static" elevation={0}>
      <Container maxWidth="xl">
        <Toolbar data-testid="app-bar__tool-bar" disableGutters>
          <img
            width="64px"
            height="64px"
            alt="Archax logo"
            src={getLogoSrc(user)}
            onError={(e) => {
              ;(e.target as HTMLImageElement).src = ArchaxLogo
            }}
            style={{
              backgroundColor: isBackgroundTransparent(user) ? 'transparent' : 'white',
              padding: '7px',
              borderRadius: '6px',
              objectFit: 'cover',
            }}
          />
          <Box sx={{ alignContents: 'center', flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: 'background.paper' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {userMenu.map((page) => {
                if (page.type === MenuPageTypes.Link) {
                  return (
                    <MenuItem key={page.url} onClick={page.onClick}>
                      <Typography role="menu-item" textAlign="center">
                        {t(page.title, {
                          defaultValue: page.title,
                        })}
                      </Typography>
                    </MenuItem>
                  )
                }
                if (page.type === MenuPageTypes.Menu) {
                  return (
                    <React.Fragment key={page.url}>
                      <MenuItem key={page.url} onClick={page.onClick}>
                        <Typography role="menu-item" textAlign="center">
                          {t(page.title, {
                            defaultValue: page.title,
                          })}
                        </Typography>
                      </MenuItem>
                      <Menu
                        sx={{
                          display: { xs: 'block', md: 'none' },
                        }}
                        key={`key-${page.title}`}
                        id={`menu-${page.title}`}
                        anchorEl={page.anchorEl}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        open={Boolean(page.anchorEl)}
                        onClose={page.onClose}
                      >
                        {page.subItems?.map((subItem) => {
                          return (
                            <MenuItem key={subItem.url} onClick={subItem.onClick}>
                              <Typography key={`typo-key-${subItem.url}`} role="menu-item" textAlign="center">
                                {t(subItem.title, {
                                  defaultValue: subItem.title,
                                })}
                              </Typography>
                            </MenuItem>
                          )
                        })}
                      </Menu>
                    </React.Fragment>
                  )
                }
                return <React.Fragment key={page.url}></React.Fragment>
              })}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {userMenu.map((page) => {
              if (page.type === MenuPageTypes.Link) {
                return (
                  <Button key={page.url} onClick={page.onClick} sx={{ my: 2, color: 'white', display: 'block' }}>
                    {t(page.title, {
                      defaultValue: page.title,
                    })}
                  </Button>
                )
              }
              if (page.type === MenuPageTypes.Menu) {
                return (
                  <React.Fragment key={page.url}>
                    <Button
                      data-testid="app-bar__button"
                      key={page.url}
                      onClick={page.onClick}
                      sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      {t(page.title, {
                        defaultValue: page.title,
                      })}
                    </Button>
                    <Menu
                      sx={{ display: { xs: 'none', md: 'flex' } }}
                      key={`key-${page.title}`}
                      id={`menu-${page.title}`}
                      anchorEl={page.anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      open={Boolean(page.anchorEl)}
                      onClose={page.onClose}
                    >
                      {page.subItems?.map((subItem) => {
                        return (
                          <MenuItem key={subItem.url} onClick={subItem.onClick}>
                            <Typography key={`typo-key-${subItem.url}`} role="menu-item" textAlign="center">
                              {t(subItem.title, {
                                defaultValue: subItem.title,
                              })}
                            </Typography>
                          </MenuItem>
                        )
                      })}
                    </Menu>
                  </React.Fragment>
                )
              }
              return <React.Fragment key={page.url}></React.Fragment>
            })}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={() => setOpenUserProfileDialog(true)}
                sx={{ border: 'solid', borderColor: 'white', color: 'white' }}
              >
                <PersonIcon sx={{ fontSize: 14 }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Accounts balances">
              <IconButton
                onClick={handleOpenAccountsBalance}
                sx={{ border: 'solid', borderColor: 'white', color: 'white', ml: 3 }}
              >
                <AccountBalanceWalletIcon sx={{ fontSize: 14 }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-accounts-balance"
              anchorEl={anchorElAccountsBalance}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElAccountsBalance)}
              onClose={handleCloseAccountsBalance}
            >
              {anchorElAccountsBalance && <AccountsBalances />}
            </Menu>
          </Box>
          <Stack
            data-testid="app-bar__tool-bar__logout-button"
            direction="row"
            alignItems="center"
            gap={1}
            sx={{
              border: 'solid',
              borderColor: 'white',
              color: 'white',
              borderRadius: 25,
              marginLeft: 3,
              marginRight: 3,
              paddingLeft: 3,
              paddingRight: 3,
              cursor: 'pointer',
            }}
            onClick={() => handleLogout()}
          >
            <Typography fontSize={14}>Log out</Typography>
            <LogoutIcon />
          </Stack>
        </Toolbar>
      </Container>
      <Dialog
        open={openUserProfileDialog}
        onClose={() => setOpenUserProfileDialog(false)}
        title=""
        maxWidth="md"
        fullWidth
      >
        <UserDetailsForm
          mode={UserDetailFormMode.PersonalChange}
          email={user.email}
          name={user.name}
          userId={user.id}
          organization={user.organization}
          onClose={() => {
            setOpenUserProfileDialog(false)
          }}
        />
      </Dialog>
      <Dialog
        open={openImportTokenDialog}
        onClose={() => setOpenImportTokenDialog(false)}
        title="Import token"
        maxWidth="sm"
        fullWidth
      >
        <ImportToken onClose={() => setOpenImportTokenDialog(false)} />
      </Dialog>
    </AppBar>
  )
}
export default Header
