import { isValidAddress } from '@archax/address-validator'
import {
  APIErrorResponse,
  ChainProtocol,
  CreateExternalTokenOperationData,
  Currency,
  EVM_CHAIN_PROTOCOLS,
  EXTERNAL_TOKENS_PROTOCOLS,
  OperationType,
  READABLE_PROTOCOL,
} from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, CircularProgress, DialogActions, TextField as MuiTextField } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import { getExternalToken } from '../../../../api/tokens'
import { Select } from '../../../../components/ui/Select'
import { TextField } from '../../../../components/ui/TextField'
import onApiError from '../../../../util/on-api-error'
import { Switch } from '../../../../components/ui/Switch'
import { PROTOCOLS_WITH_PRIVACY_ENABLED } from '../../../../constants'

interface ImportTokenProps {
  onClose: () => void
}

enum ImportTokenSteps {
  Fetch = 'FETCH',
  Import = 'IMPORT',
}

function ImportToken({ onClose }: ImportTokenProps) {
  const [addressFound, setAddressFound] = useState<boolean>(true)
  const [showPrivacyEnabledField, setShowPrivacyEnabledField] = useState(false)
  const [getExternalTokenToggle, setGetExternalTokenToggle] = useState<boolean>(false)

  const validationSchema = Yup.object().shape({
    protocol: Yup.string().oneOf(EXTERNAL_TOKENS_PROTOCOLS).required(),
    address: Yup.string()
      .test('is-valid-address', 'Invalid address', (value: string | undefined, ctx: Yup.TestContext): boolean => {
        const { protocol } = ctx.parent
        if (!value || !protocol) {
          return false
        }
        return isValidAddress(value, protocol)
      })
      .required(),
    step: Yup.string().oneOf(Object.values(ImportTokenSteps)).required(),
    currency: Yup.string().when('step', ([step]) => {
      if (step === ImportTokenSteps.Import) {
        return Yup.string().oneOf(Object.values(Currency)).required()
      }
      return Yup.string()
    }),
  })

  const initialValues = {
    protocol: '',
    address: '',
    step: ImportTokenSteps.Fetch,
    currency: '',
    privacyEnabled: false,
  }

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues as any,
    resolver: yupResolver(validationSchema),
  })
  const watchAddress = watch('address')
  const watchPrivacyEnabled = watch('privacyEnabled')
  const watchProtocol = watch('protocol')

  useEffect(() => {
    if (watchAddress) {
      setValue('step', ImportTokenSteps.Fetch)
    }
  }, [watchAddress, setValue])

  useEffect(() => {
    if (watchPrivacyEnabled) {
      setValue('step', ImportTokenSteps.Fetch)
    }
  }, [watchPrivacyEnabled, setValue])

  useEffect(() => {
    if (watchProtocol) {
      if (
        EVM_CHAIN_PROTOCOLS.includes(watchProtocol as ChainProtocol) &&
        PROTOCOLS_WITH_PRIVACY_ENABLED.includes(watchProtocol as ChainProtocol)
      ) {
        setShowPrivacyEnabledField(true)
      } else {
        setShowPrivacyEnabledField(false)
        setValue('privacyEnabled', false)
      }
    }
  }, [watchProtocol])

  const { data: externalTokenData, isFetching: externalTokenisFetching } = useQuery(
    ['fetch-token', getValues('protocol'), getValues('address')],
    () =>
      getExternalToken(
        getValues('protocol') as ChainProtocol,
        getValues('address') as string,
        getValues('privacyEnabled') as string,
      ),
    {
      retry: false,
      enabled: getExternalTokenToggle,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      onSuccess: () => {
        setValue('step', ImportTokenSteps.Import)
        setAddressFound(true)
      },
      onError: (error: AxiosError<APIErrorResponse>) => {
        const { response } = error
        if (response?.status === 400) {
          setAddressFound(false)
          return onApiError(error)
        }
        return onApiError(error)
      },
      onSettled: () => {
        setGetExternalTokenToggle(false)
      },
    },
  )

  const { mutate: createExternalTokenMutation } = useMutation(
    (data: CreateExternalTokenOperationData) => createOperation(OperationType.CreateExternalToken, data),
    {
      onError: onApiError,
      onSuccess: () => {
        toast.success('Token import request sent for approval')
        setShowPrivacyEnabledField(false)
        onClose()
      },
    },
  )

  const onConfirm = (data: CreateExternalTokenOperationData & { step: ImportTokenSteps }) => {
    const { step, ...rest } = data
    if (getValues('step') === ImportTokenSteps.Fetch) {
      setGetExternalTokenToggle(true)
    }
    if (getValues('step') === ImportTokenSteps.Import) {
      createExternalTokenMutation({ ...rest, notes: '' })
    }
  }

  return (
    <form onSubmit={handleSubmit(onConfirm)}>
      <Box marginTop={2} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        <Select
          name="protocol"
          control={control}
          label="Protocol"
          options={EXTERNAL_TOKENS_PROTOCOLS.map((protocol) => ({
            label: READABLE_PROTOCOL[protocol],
            value: protocol,
          }))}
          sx={{ mb: 4 }}
        />

        {showPrivacyEnabledField && (
          <Switch
            name="privacyEnabled"
            control={control}
            label="Enable privacy"
            tooltip="Add privacy with Silent Data [Rollup]"
          />
        )}
        <TextField name="address" control={control} label="Address" sx={{ mb: 4 }} />

        {externalTokenisFetching && (
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ mb: 4 }}>
            <CircularProgress />
          </Box>
        )}
        {!externalTokenisFetching && getValues('step') === ImportTokenSteps.Import && externalTokenData && (
          <>
            <MuiTextField
              id="token-name-label"
              label="Token Name"
              variant="standard"
              fullWidth
              value={externalTokenData.data.name}
              disabled
              sx={{ mb: 4 }}
            />
            <MuiTextField
              id="symbol-label"
              label="Symbol"
              variant="standard"
              fullWidth
              value={externalTokenData.data.symbol}
              disabled
              sx={{ mb: 4 }}
            />
            <MuiTextField
              id="decimals-label"
              label="Decimals"
              variant="standard"
              fullWidth
              value={externalTokenData.data.decimals}
              disabled
              sx={{ mb: 4 }}
            />
            <Select
              name="currency"
              control={control}
              label="Currency"
              options={Object.values(Currency).map((currencyCode) => ({
                label: currencyCode,
                value: currencyCode,
              }))}
              sx={{ mb: 4 }}
            />
          </>
        )}
      </Box>
      <DialogActions sx={{ marginBottom: 2, display: 'flex', justifyContent: 'center' }}>
        <Button
          data-testid="dialog__cancel-button"
          onClick={onClose}
          sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
          variant="contained"
          fullWidth
        >
          Cancel
        </Button>
        <Button
          disabled={!isValid}
          data-testid="dialog__confirm-button"
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Import
        </Button>
      </DialogActions>
    </form>
  )
}

export default ImportToken
