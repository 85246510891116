import { OperationType, UpdateTokenDocsForm } from '@archax/shared-types'
import { AttachFile, DeleteForever } from '@mui/icons-material'
import { Box, Button, CircularProgress, Grid, Input, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { createOperationWithFiles } from '../../../../api/operations'
import Dialog from '../../../../components/Dialog/Dialog'
import onApiError from '../../../../util/on-api-error'
import { Docs } from './ShowTokenDocuments'

function calcDocumentSize(size: number) {
  if (size < 1000) {
    return `${size} B`
  }
  if (size < 1000000) {
    return `${(size / 1000).toFixed(2)} KB`
  }
  return `${(size / 1000000).toFixed(2)} MB`
}

function borderRadiusRowRule(row: number, totalRows: number) {
  if (totalRows === 1) {
    return '4px'
  }
  if (row === 0) {
    return '4px 4px 0 0'
  }
  if (row === totalRows - 1) {
    return '0 0 4px 4px'
  }
}

interface IDragDropFiles {
  open: boolean
  onClose: (_: boolean) => void
  initialDocuments: Docs[]
  tokenId: string
}

function UpdateTokenDocs({ initialDocuments = [], onClose, open, tokenId }: IDragDropFiles) {
  const [dynamicDocuments, setDynamicDocuments] = useState<Docs[] | null>(Array.from(initialDocuments || []) as Docs[])
  const [docsAreSame, setDocsAreSame] = useState(true)
  const [isUploading, setIsUploading] = useState(false)

  const { mutateAsync } = useMutation(
    (formData: UpdateTokenDocsForm) => {
      return createOperationWithFiles(OperationType.UpdateTokenDocs, formData, tokenId)
    },
    {
      onSuccess: () => {
        toast.success('Token documents update request sent for approval')
      },
      onError: onApiError,
    },
  )

  useEffect(() => {
    if (open) {
      setDynamicDocuments(Array.from(initialDocuments || []) as Docs[])
      setIsUploading(false)
    }
  }, [initialDocuments, open])

  const inputRef = useRef(null)

  const handleDrag = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
    } else if (e.type === 'dragleave') {
    }
  }

  function handleFiles(files: any) {
    let filesArray = Array.from(files)
    if (dynamicDocuments) {
      filesArray = [...dynamicDocuments, ...filesArray]
    }

    const notPdf = filesArray.find((file: any) => file.type === 'application/pdf')
    if (notPdf === undefined) {
      toast.error('Only PDF files are supported')
      return
    }
    setDynamicDocuments(filesArray as Docs[])
  }

  const handleDrop = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files)
    }
  }

  const handleSave = async () => {
    setIsUploading(true)
    const oldDocs: Record<string, any> = {}

    const customNames: Record<string, any> = {}

    initialDocuments.forEach((doc: any) => {
      if (dynamicDocuments && dynamicDocuments.find((d: any) => d.name === doc.name)) {
        oldDocs[doc.key] = true
        customNames[doc.name] = doc.customName
        const index = dynamicDocuments!.findIndex((d: any) => d.name === doc.name)
        dynamicDocuments!.splice(index, 1)
      } else {
        oldDocs[doc.key] = false
      }
    })

    if (dynamicDocuments) {
      dynamicDocuments.forEach(({ name, customName }: any) => {
        customNames[name] = customName || name
      })
    }

    const sendData: any = {}
    sendData.oldDocs = oldDocs
    sendData.document = dynamicDocuments
    sendData.customDocName = customNames
    await mutateAsync(sendData)
    onClose(false)
  }

  const onButtonClick = () => {
    ;(inputRef.current! as any).click()
  }

  useEffect(() => {
    if (dynamicDocuments && initialDocuments) {
      const initialDocumentsNames = [...initialDocuments].map((doc: any) => doc.name)
      ;[...initialDocumentsNames].forEach((doc: any) => {
        if (!dynamicDocuments!.find((d: any) => d.name === doc)) {
          setDocsAreSame(false)
        }
      })
    } else {
      setDocsAreSame(false)
    }
  }, [dynamicDocuments, setDocsAreSame, initialDocuments])

  return (
    <Dialog open={open} onClose={() => onClose(false)} title="Edit documents">
      <Box width={'744px'}>
        {isUploading && <CircularProgress />}
        {!isUploading && (
          <>
            <Grid
              container
              sx={{
                border: dynamicDocuments ? '0px' : '1px solid var(--other-divider, #E0E2E4)',
                borderRadius: '4px',
                minHeight: dynamicDocuments ? '0px' : '200px',
                alignContent: 'center',
              }}
            >
              {!dynamicDocuments && (
                <Grid
                  container
                  direction={'column'}
                  sx={{ justifyContent: 'center', alignContent: 'center', height: '100%' }}
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    Drop files here
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: 'var(--text-secondary, rgba(22, 25, 27, 0.50))', textAlign: 'center' }}
                  >
                    Supported files: PDF. Max size 100MB.
                  </Typography>
                </Grid>
              )}
              {dynamicDocuments &&
                dynamicDocuments.length > 0 &&
                dynamicDocuments.map((file: any, index: number) => (
                  <Grid
                    key={index}
                    container
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: ' 1px solid var(--other-divider, #E0E2E4)',
                      height: '52px',
                      borderRadius: borderRadiusRowRule(index, dynamicDocuments.length),
                      padding: '0 12px',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '14px',
                        color: 'var(--text-secondary, rgba(22, 25, 27, 0.50))',
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        width: '200px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {file.name}
                    </Typography>
                    {!file.key && (
                      <Input
                        placeholder="Enter custom name"
                        disabled={!!file.key}
                        sx={{
                          width: '200px',
                          ':after': { borderBottom: 'none' },
                          ':before': { borderBottom: 'none' },
                          color: 'rgba(22;25;27;0.30)',
                          fontSize: 15,
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          lineHeight: 24,
                          wordWrap: 'break-word',
                        }}
                        onChange={(e) => {
                          const filesArray = dynamicDocuments
                          filesArray[index].customName = e.target.value + file.name.slice(file.name.lastIndexOf('.'))
                          setDynamicDocuments(filesArray)
                        }}
                      />
                    )}
                    <Grid container sx={{ width: '110px', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '14px',
                          color: 'var(--text-secondary, rgba(22, 25, 27, 0.50))',
                          fontFeatureSettings: "'clig' off, 'liga' off",
                        }}
                      >
                        {file.size ? calcDocumentSize(file.size) : ''}
                      </Typography>
                      <Button
                        onClick={() => {
                          const filesArray = dynamicDocuments
                          if (filesArray.length === 1) {
                            setDynamicDocuments(null)
                            return
                          }
                          filesArray.splice(index, 1)
                          setDynamicDocuments([...filesArray])
                        }}
                        size="small"
                        sx={{ ':hover': { backgroundColor: 'transparent' }, minWidth: 0, width: 'fit-content' }}
                      >
                        <DeleteForever sx={{ color: '#16191B80' }} />
                      </Button>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            {dynamicDocuments && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: 'var(--text-secondary, rgba(22, 25, 27, 0.50))', textAlign: 'left' }}
                >
                  Supported files: PDF. Max size 100MB.
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: 'var(--text-secondary, rgba(22, 25, 27, 0.50))', textAlign: 'right' }}
                >
                  {dynamicDocuments.length} {dynamicDocuments.length === 1 ? 'file' : 'files'}
                </Typography>
              </Box>
            )}

            <Box
              mt={4}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onButtonClick}
                  component="label"
                  startIcon={<AttachFile />}
                >
                  Add Files
                </Button>
                <input
                  ref={inputRef}
                  hidden
                  accept="application/pdf"
                  type="file"
                  multiple
                  onChange={(e) => {
                    handleFiles(e.target.files)
                  }}
                />
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    onClose(false)
                  }}
                  sx={{
                    marginLeft: '12px',
                    color: '#13103E',
                    borderColor: '#13103E',
                    ':hover': { borderColor: '#13103E', backgroundColor: 'transparent' },
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginLeft: '12px' }}>
                  Save
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  )
}

export default UpdateTokenDocs
