import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community'
import { TABLE_HEADER_NAMES, Trader, TraderStatus as TraderStatusEnum } from '@archax/shared-types'
import { Card, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Container } from '@mui/system'
import { getTraders, getTradersCSVUrl } from '../../api/traders'
import Dialog from '../../components/Dialog/Dialog'
import { ServerSideGrid } from '../../components/ServerSideGrid'
import { AddButton } from '../../components/ui/AddButton'
import AddTraderForm from './components/AddTraderForm'
import { TraderStatus } from './components/TraderStatus'
import { TraderStatusChangeButton } from './components/TraderStatusChangeButton'
import { substringFilterParams } from '../../util/common-grid-options'

function ListTraders() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [showCreateTraderDialog, setShowCreateTraderDialog] = useState(false)
  const [gridApi, setGridApi] = useState<null | GridApi>(null)

  const columnDefs: ColDef<Trader>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: TABLE_HEADER_NAMES.common.name,
        flex: 3,
        maxWidth: 800,
        minWidth: 400,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'status',
        headerName: TABLE_HEADER_NAMES.common.trader_status,
        flex: 1,
        minWidth: 160,
        sortable: false,
        cellRenderer: (params: ICellRendererParams<Trader>) => {
          return <TraderStatus trader={params.data!} />
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.values(TraderStatusEnum),
        },
      },
      {
        field: 'actions',
        flex: 1,
        minWidth: 150,
        sortable: false,
        align: 'right',
        cellRenderer: (params: ICellRendererParams<Trader>) => {
          return (
            <TraderStatusChangeButton
              trader={params.data!}
              refetch={() => params.api?.refreshServerSide()}
              size="small"
            />
          )
        },
        filter: false,
      },
    ],
    [],
  )

  const onTraderCreationSuccess = () => {
    setShowCreateTraderDialog(false)
    gridApi?.refreshServerSide()
  }

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      onCellClicked: (params) => {
        const excludedColumns = ['actions']
        const colId = params.colDef.field
        if (!excludedColumns.includes(colId as string)) {
          navigate(`/holders/${params.data!.id}`)
        }
      },
    }),
    [navigate, columnDefs],
  )

  const serverSideGrid = useMemo(() => {
    return (
      <ServerSideGrid
        gridOptions={gridOptions}
        queryFn={getTraders}
        csvExportUrlGetter={getTradersCSVUrl}
        setParentGridApi={setGridApi}
      />
    )
  }, [])

  return (
    <Container maxWidth="xl">
      <Card sx={{ p: 7 }}>
        <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'} justifyContent={'space-between'}>
          <Typography align="left" variant="h3">
            {t('holder.managementScreen')}
          </Typography>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <AddButton
              name={t('holder.createNew').toLowerCase().replace(' ', '-')}
              ariaLabel={t('holder.createNew')}
              onClick={() => setShowCreateTraderDialog(true)}
            ></AddButton>
          </Box>
        </Box>
        <Typography align="left">{t('holder.forms.selectManageHolder')}</Typography>

        <Box>{serverSideGrid}</Box>

        <Dialog
          title={t('holder.dialog.creation')}
          onClose={() => setShowCreateTraderDialog(false)}
          open={showCreateTraderDialog}
        >
          <AddTraderForm
            // ARX-462 pass all names or request in the component when traders have paging implemented
            onSuccess={() => onTraderCreationSuccess()}
          />
        </Dialog>
      </Card>
    </Container>
  )
}

export default ListTraders
