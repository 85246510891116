import { AxiosResponse } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { OperationType } from '@archax/shared-types'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import Dialog from '../../../../components/Dialog/Dialog'
import { getOperationDocuments } from '../../../../api/operations'

type FetchedDocuments = {
  name: string
  link: string
}
function CreateTokenDocumentsGrid(documents: AxiosResponse<any, FetchedDocuments[]>) {
  return (
    documents?.data?.map((doc: { name: string; link: string }) => (
      <Grid container sx={{ border: '1px #E0E2E4 solid', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography
          variant="body2"
          sx={{
            margin: '12px 0px 12px 12px',
            fontSize: '14px',
            color: '#131516',
            fontFeatureSettings: "'clig' off, 'liga' off",
            maxWidth: '640px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {doc.name}
        </Typography>
        <Button variant="contained" size="small" sx={{ margin: '12px' }} href={doc.link} target="_blank">
          View
        </Button>
      </Grid>
    )) || <></>
  )
}

const parseUpdateDocumentsHeader: Record<string, any> = {
  newDocs: 'New documents for approval',
  oldDocs: 'Current documents',
}

interface IUpdateDocuments {
  newDocs: FetchedDocuments[]
  oldDocs: FetchedDocuments[]
}

function UpdateTokenDocumentsGrid(documents: AxiosResponse<any, IUpdateDocuments>) {
  return (
    documents && (
      <Grid container>
        {Object.keys(documents.data).map((key: string) => (
          <Grid container flex={1} sx={{ alignContent: 'flex-start' }}>
            <Grid
              container
              sx={{
                border: '1px #E0E2E4 solid',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: '#F8FBFD',
                height: '52px',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  margin: '12px 0px 12px 12px',
                  fontSize: '14px',
                  color: '#837D93',
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontWeight: 'bold',
                }}
              >
                {parseUpdateDocumentsHeader[key]}
              </Typography>
            </Grid>
            {documents.data[key].map((doc: FetchedDocuments) => (
              <Grid
                container
                sx={{
                  border: '1px #E0E2E4 solid',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '52px',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    margin: '12px 0px 12px 12px',
                    fontSize: '14px',
                    color: '#131516',
                    fontFeatureSettings: "'clig' off, 'liga' off",
                  }}
                >
                  {doc.name}
                </Typography>
                <Button variant="contained" size="small" sx={{ margin: '12px' }} href={doc.link} target="_blank">
                  View
                </Button>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    )
  )
}

const documentsGridBuilder: Record<string, any> = {
  [OperationType.CreateToken]: CreateTokenDocumentsGrid,
  [OperationType.UpdateTokenDocs]: UpdateTokenDocumentsGrid,
}

type ShowDocumentsButtonProps = {
  onClick: () => void
}
export function ShowDocumentsButton({ onClick }: ShowDocumentsButtonProps) {
  return (
    <Button
      sx={{
        color: '#13103E',
        borderColor: '#13103E',
        ':hover': { backgroundColor: 'transparent', borderColor: '#13103E' },
        fontWeight: 'bold',
        fontSize: '14px',
      }}
      variant="outlined"
      size="small"
      onClick={onClick}
    >
      View Documents
    </Button>
  )
}

type ShowDocumentsDialogProps = {
  operationId?: string
  operationType?: OperationType
  onClose: () => void
}
export function ShowDocumentsDialog({ operationId, operationType, onClose }: ShowDocumentsDialogProps) {
  const { isLoading, data: documents } = useQuery(
    [`get-operation-${operationId}-documents`],
    () => getOperationDocuments(operationId as string),
    {
      refetchOnWindowFocus: false,
    },
  )
  return (
    <Dialog onClose={onClose} title="Documents" open={true}>
      <Box sx={{ width: '750px' }}>
        {isLoading ? (
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ mb: 4, margin: '1.5em 0 0 0' }}>
            <CircularProgress />
          </Box>
        ) : (
          documentsGridBuilder[operationType!](documents)
        )}
      </Box>
    </Dialog>
  )
}
