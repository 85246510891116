import {
  ChainProtocol,
  OperationType,
  READABLE_PROTOCOL,
  READABLE_STANDARD,
  READABLE_TOKEN_STANDARD_STATUS,
  TokenStandard,
} from '@archax/shared-types'
import { Button, Card, CircularProgress, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Container } from '@mui/system'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ColDef, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { createOperation } from '../../api/operations'
import { listEvmStandards } from '../../api/standards'
import { ClientSideGrid } from '../../components/ClientSideGrid'
import Dialog from '../../components/Dialog/Dialog'
import onApiError from '../../util/on-api-error'

function ListStandards() {
  const [showDeployDialog, setShowDeployDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState<TokenStandard>()
  const { t } = useTranslation()

  const { isLoading, data, refetch } = useQuery(['evm-standards'], () => listEvmStandards(), {
    retry: false,
    onError: onApiError,
    cacheTime: 0,
  })

  const { mutate } = useMutation(
    () =>
      createOperation(OperationType.DeployImplementation, {
        protocol: selectedRow?.chain.protocol,
        version: selectedRow?.version,
      }),
    {
      onSuccess: () => {
        toast.success('implementation contract deployment request sent for approval')
      },
      onError: onApiError,
    },
  )

  const handleDeploy = () => {
    mutate()
    setShowDeployDialog(false)
  }

  const columnDefs: ColDef<TokenStandard>[] = useMemo(
    () => [
      {
        field: 'version',
        headerName: t('headers.common.version'),
        minWidth: 250,
        sortable: false,
      },
      {
        field: 'name',
        headerName: t('headers.common.standard'),
        sortable: false,
        valueGetter: (params: ValueGetterParams<TokenStandard>) => {
          return READABLE_STANDARD[params.data!.name]
        },
      },
      {
        field: 'protocol',
        headerName: t('headers.common.protocol'),
        sortable: false,
        valueGetter: (params: ValueGetterParams<TokenStandard>) => {
          return READABLE_PROTOCOL[params.data!.chain.protocol as ChainProtocol]
        },
      },
      {
        field: 'status',
        headerName: t('headers.common.status'),
        sortable: false,
        valueGetter: (params: ValueGetterParams<TokenStandard>) => {
          return READABLE_TOKEN_STANDARD_STATUS[params.data!.status]
        },
      },
      {
        field: 'actions',
        minWidth: 300,
        sortable: false,
        cellRenderer: (params: ICellRendererParams<TokenStandard>) => {
          if (params.data!.deploymentResult) {
            return ''
          }

          return (
            <Stack direction={'row'}>
              <Box marginRight={1}>
                <Button
                  data-testid="standards-deploy_implementation-button"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setSelectedRow(params.data!)
                    setShowDeployDialog(true)
                  }}
                >
                  {t('standards.buttons.deployImplementation')}
                </Button>
              </Box>
            </Stack>
          )
        },
      },
    ],
    [t],
  )

  const { data: standards } = data?.data || {}

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      rowData: standards || [],
      domLayout: 'autoHeight',
      defaultColDef: { resizable: true },
    }),
    [columnDefs, standards],
  )

  return (
    <Container data-testid="admin-approval" maxWidth="xl">
      <Card sx={{ p: 7 }}>
        <Typography data-testid="admin-approval__title" align="left" variant="h3">
          {t('standards.listStandards.title')}
        </Typography>
        {isLoading && <CircularProgress />}
        {!isLoading && data && (
          <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
            <ClientSideGrid gridOptions={gridOptions} handleRefresh={refetch} />
          </Box>
        )}
      </Card>
      <Dialog
        title={t('standards.dialogs.deployImplementation')}
        onConfirm={handleDeploy}
        confirmLabel={t('standards.buttons.deployImplementation')}
        onClose={() => setShowDeployDialog(false)}
        open={showDeployDialog}
        showCancel
      >
        <Typography variant="body2">{t('standards.buttons.deployImplementationHelper')} </Typography>
      </Dialog>
    </Container>
  )
}

export default ListStandards
