import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Token } from '@archax/shared-types'
import { ModeEditOutlineOutlined } from '@mui/icons-material'
import { Box, Button, Grid, Typography, styled } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { ColDef, GridApi, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { getTokenDocuments } from '../../../../api/tokens'
import { useGlobalContext } from '../../../../context'
import { formatDate } from '../../../../util/formatters'
import { hasUserRole } from '../../../../util/user-roles'
import { ClientSideGrid } from '../../../../components/ClientSideGrid'
import UpdateTokenDocs from './UpdateTokenDocs'

const StyledGrid = styled(Grid)(() => ({
  margin: 0,
  alignContent: 'center',
  justifyContent: 'space-between',
}))

const StyledButton = styled(Button)(() => ({
  margin: 0,
  padding: 0,
  minWidth: '34px',
  cursor: 'pointer',
  display: 'flex',
  alignContent: 'center',
}))

export interface Docs {
  name: string
  link: string
  lastModified: string
  key: string
  customName?: string
}

type ShowTokenDocumentsProps = {
  token: Token
}
function ShowTokenDocuments({ token }: ShowTokenDocumentsProps) {
  const { t } = useTranslation()
  const [gridApi, setGridApi] = useState<GridApi>()
  const [openEditDocuments, setOpenEditDocuments] = useState(false)
  const {
    state: { user },
  } = useGlobalContext()

  const { isFetching, data, refetch } = useQuery(
    [`get-token-${token.id}-documents`],
    () => getTokenDocuments(token.id as string),
    {
      refetchOnWindowFocus: false,
    },
  )

  const documents = useMemo(() => data?.data || [], [data])

  const columnDefs: ColDef<Docs>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'lastModified',
        headerName: 'Last modified',
        flex: 1,
        minWidth: 100,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Docs>) => {
          return formatDate(params.data!.lastModified)
        },
      },
      {
        field: 'hash',
        headerName: 'Hash',
        flex: 2,
        minWidth: 130,
        sortable: false,
      },
      {
        field: 'link',
        headerName: '',
        flex: 1,
        maxWidth: 230,
        sortable: false,
        cellRenderer: (params: ICellRendererParams<Docs>) => {
          const downloadUrl = `/api/tokens/${token.id}/documents/${params.data!.name.split('.pdf')[0]}`
          return (
            <>
              <Button
                variant="contained"
                size="small"
                href={params.data!.link}
                target="_blank"
                sx={{ marginRight: '12px' }}
              >
                {t('token.documents.actions.preview')}
              </Button>
              <Button variant="contained" size="small" href={downloadUrl} target="_blank">
                {t('token.documents.actions.download')}
              </Button>
            </>
          )
        },
      },
    ],
    [token.id],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      rowData: documents,
      domLayout: 'autoHeight',
      defaultColDef: { resizable: true },
      onGridReady: (params) => {
        params.api.showLoadingOverlay()
        setGridApi(params.api)
      },
    }),
    [columnDefs, documents],
  )

  useEffect(() => {
    if (gridApi) {
      isFetching ? gridApi.showLoadingOverlay() : gridApi.hideOverlay()
    }
  }, [isFetching])

  return (
    <>
      <Box>
        <StyledGrid container mt={1} mb={4}>
          <Typography variant="h2">{t('token.documents.title')}</Typography>
          {hasUserRole(user) && (
            <StyledButton onClick={() => setOpenEditDocuments(true)} disabled={token.hasExternalOwner}>
              <ModeEditOutlineOutlined color={token.hasExternalOwner ? 'disabled' : 'primary'} />
            </StyledButton>
          )}
        </StyledGrid>
        <Box width={'100%'} className="ag-theme-material">
          <ClientSideGrid gridOptions={gridOptions} handleRefresh={() => refetch()} />
        </Box>
      </Box>
      <UpdateTokenDocs
        initialDocuments={documents}
        onClose={() => setOpenEditDocuments(false)}
        tokenId={token.id}
        open={openEditDocuments}
      />
    </>
  )
}

export default ShowTokenDocuments
