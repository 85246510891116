import { passwordPolicyMessage, userPasswordPolicy } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  Typography,
} from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { adminPasswordReset, changePassword } from '../../../api/users'
import TextField from '../../../components/ui/TextField/TextField'
import onApiError from '../../../util/on-api-error'

export enum UserDetailFormMode {
  PersonalChange = 'PersonalChange',
  AdminReset = 'AdminReset',
}

interface UserDetailsFormProps {
  name: string
  email: string
  onClose: () => void
  mode: UserDetailFormMode
  userId: string
  organization: { name: string }
}

interface UserDetailReadProps extends UserDetailsFormProps {
  onChangePassword: () => void
}

const StyledDisabledMuiTextField = ({ sx, ...props }: any) => (
  <MuiTextField
    disabled
    variant="standard"
    InputProps={{
      disableUnderline: true,
    }}
    sx={{
      mb: '24px',
      border: '0px',
      '> label': {
        color: '#131516 !important',
      },
      '> div > input': {
        WebkitTextFillColor: '#131516 !important',
      },
      ...sx,
    }}
    {...props}
  />
)

function UserDetailRead({ email, name, organization, onChangePassword, onClose, mode, userId }: UserDetailReadProps) {
  const [password, setPassword] = useState('')

  const { mutate, isLoading } = useMutation((userId: string) => adminPasswordReset(userId), {
    onSuccess: (data) => {
      toast.success('Password changed successfully')
      const { password } = data.data as { password: string }
      setPassword(password)
    },
  })

  return isLoading ? (
    <CircularProgress />
  ) : (
    <>
      <StyledDisabledMuiTextField value={name} label="Name" fullWidth />
      <StyledDisabledMuiTextField value={email} label="Email" fullWidth />
      <StyledDisabledMuiTextField value={organization.name} label="Organization" fullWidth />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {mode === UserDetailFormMode.PersonalChange ? (
          <Button size="small" variant="contained" sx={{ width: 'fit-content', mb: '28px' }} onClick={onChangePassword}>
            <Typography variant="button" fontWeight={700}>
              Change password
            </Typography>
          </Button>
        ) : (
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '32px', width: '100%' }}
          >
            <StyledDisabledMuiTextField
              value={password === '' ? '**************' : password}
              label="Password"
              sx={{ mb: 0 }}
            />
            <Button
              color="cancel"
              size="small"
              variant="outlined"
              sx={{ width: 'fit-content', height: 'fit-content' }}
              onClick={() => mutate(userId)}
            >
              <Typography variant="button" fontWeight={700}>
                Generate new password
              </Typography>
            </Button>
          </Box>
        )}
        <Button color="cancel" variant="outlined" sx={{ width: 'fit-content' }} onClick={onClose}>
          <Typography variant="button" fontWeight={700}>
            Close
          </Typography>
        </Button>
      </Box>
    </>
  )
}

interface ChangePasswordFormProps {
  onBack: () => void
}

const passwordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current password is required'),
  newPassword: Yup.string().matches(userPasswordPolicy, passwordPolicyMessage).required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
    .required('Confirm password is required'),
})

function ChangePasswordForm({ onBack }: ChangePasswordFormProps) {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  // use mutation to update password changePassword
  const { mutate, isLoading } = useMutation(
    (data: { password: string; newPassword: string }) => changePassword(data.password, data.newPassword),
    {
      onSuccess: (data) => {
        toast.success('Password changed successfully')
        onBack()
      },
      onError: onApiError,
    },
  )

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(passwordValidationSchema),
  })

  const onSubmit = (data: any) => {
    mutate({ password: data.currentPassword, newPassword: data.newPassword })
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="currentPassword"
        label="Current password"
        control={control}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        name="newPassword"
        label="New password"
        control={control}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        name="confirmPassword"
        label="Confirm password"
        control={control}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button color="cancel" variant="outlined" sx={{ width: 'fit-content' }} onClick={onBack}>
          <Typography variant="button" fontWeight={700}>
            Back
          </Typography>
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{ width: 'fit-content' }}
          disabled={!isDirty || !isValid}
          type="submit"
        >
          <Typography variant="button" fontWeight={700}>
            Confirm
          </Typography>
        </Button>
      </Box>
    </form>
  )
}

enum FormSteps {
  Read = 'Read',
  ChangePassword = 'Edit',
}

function UserDetailsForm({ email, name, organization, onClose, mode, userId }: UserDetailsFormProps) {
  const [formSteps, setFormSteps] = useState(FormSteps.Read)
  return (
    <div>
      <Typography sx={{ mb: 4 }} variant="subtitle1">
        User Details
      </Typography>
      {formSteps === FormSteps.Read && (
        <UserDetailRead
          userId={userId}
          mode={mode}
          email={email}
          name={name}
          organization={organization}
          onChangePassword={() => setFormSteps(FormSteps.ChangePassword)}
          onClose={onClose}
        />
      )}
      {formSteps === FormSteps.ChangePassword && <ChangePasswordForm onBack={() => setFormSteps(FormSteps.Read)} />}
    </div>
  )
}

export default UserDetailsForm
