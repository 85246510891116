"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOKEN_BALANCE_ERROR_PLACEHOLDER_VALUE = exports.READABLE_TOKEN_STANDARD_STATUS = exports.READABLE_TOKEN_TYPE = exports.CSV_MIME_TYPE = exports.READABLE_PRICE_TYPE = exports.READABLE_DATE_FORMAT = exports.READABLE_BLACKLIST_STATUS = exports.READABLE_KYC_STATUS = exports.READABLE_STANDARD = exports.READABLE_USER_ROLE = exports.READABLE_PROTOCOL = exports.READABLE_OPERATION_TYPE = exports.READABLE_OPERATION_STATUS = void 0;
const operation_1 = require("./operation");
const token_1 = require("./token");
const user_1 = require("./user");
__exportStar(require("./auth"), exports);
__exportStar(require("./error"), exports);
__exportStar(require("./HttpStatusCode"), exports);
__exportStar(require("./PostgresError"), exports);
__exportStar(require("./operation"), exports);
__exportStar(require("./organization"), exports);
__exportStar(require("./token"), exports);
__exportStar(require("./trader"), exports);
__exportStar(require("./user"), exports);
__exportStar(require("./tables"), exports);
__exportStar(require("./queues"), exports);
__exportStar(require("./locales"), exports);
__exportStar(require("./distribution"), exports);
__exportStar(require("./transaction"), exports);
exports.READABLE_OPERATION_STATUS = {
    [operation_1.OperationStatus.Approved]: 'Approved',
    [operation_1.OperationStatus.Cancelled]: 'Cancelled',
    [operation_1.OperationStatus.InProgress]: 'In progress...',
    [operation_1.OperationStatus.NotApproved]: 'Rejected',
    [operation_1.OperationStatus.PendingApproval]: 'Pending approval',
};
exports.READABLE_OPERATION_TYPE = {
    [operation_1.OperationType.CreateToken]: 'Create token',
    [operation_1.OperationType.CreateExternalToken]: 'Create external token',
    [operation_1.OperationType.Mint]: 'Mint',
    [operation_1.OperationType.Send]: 'Send',
    [operation_1.OperationType.Burn]: 'Burn',
    [operation_1.OperationType.Wipe]: 'Wipe',
    [operation_1.OperationType.GrantKYC]: 'Grant KYC',
    [operation_1.OperationType.RevokeKYC]: 'Revoke KYC',
    [operation_1.OperationType.Pause]: 'Pause',
    [operation_1.OperationType.Unpause]: 'Unpause',
    [operation_1.OperationType.AddTraderAddress]: 'Add trader address',
    [operation_1.OperationType.RemoveTraderAddress]: 'Remove trader address',
    [operation_1.OperationType.RegisterToken]: 'Register token',
    [operation_1.OperationType.UnregisterToken]: 'Unregister token',
    [operation_1.OperationType.TransferToken]: 'Transfer token',
    [operation_1.OperationType.UpdateTokenDocs]: 'Update token documents',
    [operation_1.OperationType.AddToBlacklist]: 'Add to blacklist',
    [operation_1.OperationType.RemoveFromBlacklist]: 'Remove from blacklist',
    [operation_1.OperationType.ArchiveToken]: 'Archive token',
    [operation_1.OperationType.UnarchiveToken]: 'Unarchive token',
    [operation_1.OperationType.RemoveTraderAddressFromWhiteList]: 'Remove trader address from whitelist',
    [operation_1.OperationType.Distribution]: 'Distribution',
    [operation_1.OperationType.ChangeOwner]: 'Change token owner',
    [operation_1.OperationType.CancelDistribution]: 'Cancel distribution',
    [operation_1.OperationType.DeployImplementation]: 'Deploy implementation',
    [operation_1.OperationType.Upgrade]: 'Upgrade token',
    [operation_1.OperationType.AssociateToken]: 'Associate token',
    [operation_1.OperationType.SetKYCableStatus]: 'Set KYCable status',
    [operation_1.OperationType.SetBlacklistableStatus]: 'Set blacklistable status',
};
exports.READABLE_PROTOCOL = {
    [token_1.ChainProtocol.Algorand]: 'Algorand',
    [token_1.ChainProtocol.Arbitrum]: 'Arbitrum',
    [token_1.ChainProtocol.Ethereum]: 'Ethereum',
    [token_1.ChainProtocol.Etherlink]: 'Etherlink',
    [token_1.ChainProtocol.Hedera]: 'Hedera',
    [token_1.ChainProtocol.Polygon]: 'Polygon',
    [token_1.ChainProtocol.XRP_Ledger]: 'XRP Ledger',
};
exports.READABLE_USER_ROLE = {
    [user_1.UserRole.Admin]: 'Admin',
    [user_1.UserRole.Approver]: 'Approver',
    [user_1.UserRole.User]: 'User',
};
exports.READABLE_STANDARD = {
    [token_1.TokenStandardType.ERC20]: 'ERC20',
    [token_1.TokenStandardType.ERC721]: 'ERC721',
    [token_1.TokenStandardType.FungibleCommon]: 'Fungible',
    [token_1.TokenStandardType.NonFungibleUnique]: 'Non fungible',
    [token_1.TokenStandardType.ASA]: 'ASA',
    [token_1.TokenStandardType.ERC20PoolToken]: 'ERC20',
    [token_1.TokenStandardType.FungibleCommonPool]: 'Fungible',
};
exports.READABLE_KYC_STATUS = {
    ENABLED: 'Whitelisted',
    DISABLED: 'Removed',
};
exports.READABLE_BLACKLIST_STATUS = {
    ENABLED: 'Blacklisted',
    DISABLED: 'Removed',
};
exports.READABLE_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
exports.READABLE_PRICE_TYPE = {
    [token_1.PriceType.Estimate]: 'Estimate',
    [token_1.PriceType.Final]: 'Final',
};
exports.CSV_MIME_TYPE = 'text/csv';
exports.READABLE_TOKEN_TYPE = {
    [token_1.TokenType.External]: 'External token',
    [token_1.TokenType.Pool]: 'Pool token',
    [token_1.TokenType.Regular]: 'Regular token',
};
exports.READABLE_TOKEN_STANDARD_STATUS = {
    [token_1.TokenStandardStatus.Disabled]: 'Disabled',
    [token_1.TokenStandardStatus.Enabled]: 'Enabled',
};
exports.TOKEN_BALANCE_ERROR_PLACEHOLDER_VALUE = -1;
