import { Token } from '@archax/shared-types'
import { Typography, Table, TableHead, TableBody } from '@mui/material'
import { ReactElement } from 'react'
import { Wrapper, StyledTableCell, StyledTableRow } from './styles'
import { useTranslation } from 'react-i18next'

type Props = {
  operationData: Record<string, unknown>
  token: Token
}

const formatCellValue = (value: any) => {
  let cellValue = 'N/A'
  if (typeof value === 'object') {
    cellValue = JSON.stringify(value, null, 2)
  }
  // operationData[key] as string || 'NOT SET'
  if (typeof value === 'string') {
    cellValue = value
  }
  if (typeof value === 'number') {
    cellValue = value.toString()
  }
  if (typeof value === 'boolean') {
    cellValue = value ? 'YES' : 'NO'
  }

  return cellValue
}

export default function OperationDetailsModalContent({ operationData, token }: Props): ReactElement {
  const { t } = useTranslation()
  const operationDataList = Object.keys(operationData).map((key) => {
    return (
      <StyledTableRow key={key}>
        <StyledTableCell>{key}</StyledTableCell>
        <StyledTableCell flex={2} align="right">
          {formatCellValue(operationData[key])}
        </StyledTableCell>
      </StyledTableRow>
    )
  })
  const content =
    operationDataList.length > 0 ? (
      <>{operationDataList}</>
    ) : (
      <Typography variant="body2" color="textSecondary">
        {t('adminApproval.operationDetailsModal.noData')}
      </Typography>
    )
  return (
    <Wrapper>
      <Typography variant="h5" mb={4}>
        {t('adminApproval.operationDetailsModal.title')}
      </Typography>
      <Table>
        <TableHead>
          <StyledTableRow sx={{ display: 'flex', width: '100%' }}>
            <StyledTableCell flex={1}> {t('adminApproval.operationDetailsModal.keyLabel')}</StyledTableCell>
            <StyledTableCell flex={2} align="right">
              {' '}
              {t('adminApproval.operationDetailsModal.valueLabel')}
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody sx={{ maxHeight: 500, overflowY: 'scroll' }}>{content}</TableBody>
      </Table>
    </Wrapper>
  )
}
