import { ColDef, GridOptions } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import dayjs, { Dayjs } from 'dayjs'
import dayJsUTCPlugin from 'dayjs/plugin/utc'
import { useEffect, useMemo, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { toast } from 'react-toastify'
import {
  ChainProtocol,
  DistributionAsset,
  DistributionOperationData,
  NFT_STANDARDS,
  OperationType,
  READABLE_DATE_FORMAT,
  READABLE_PROTOCOL,
  TABLE_HEADER_NAMES,
  Token,
} from '@archax/shared-types'
import {
  Box,
  Button,
  CircularProgress,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createOperation } from '../../../../api/operations'
import { getDistributionPayouts, getTokenDistribution } from '../../../../api/tokens'
import { FormMode } from '../../../../api/types'
import { EXPLORER_LOGOS, USDC_DECIMALS } from '../../../../constants'
import currencyFormatter from '../../../../util/currency-formatter'
import { formatDate } from '../../../../util/formatters'
import onApiError from '../../../../util/on-api-error'
import tokenBalanceFormatter from '../../../../util/token-balance-formatter'

dayjs.extend(dayJsUTCPlugin)
const Wrapper = styled('div')(() => ({
  width: '100%',
  minWidth: '42rem',
}))

export interface DistributionDefaults {
  snapshotUnixTimestamp?: number
  executeAt?: string
  distributionRate?: number
  distributionAsset?: DistributionAsset
}

interface DistributionFormProps {
  token: Token
  onClose: () => void
  defaults?: DistributionOperationData
  distributionId?: string
}

const DistributionForm = ({ token, defaults, onClose, distributionId }: DistributionFormProps) => {
  const {
    createdAt,
    id,
    symbol,
    decimals,
    standard: {
      chain: { protocol },
    },
  } = token
  const [distributionAsset, setDistributionAsset] = useState<DistributionAsset>(
    defaults?.distributionAsset ?? DistributionAsset.USDC,
  )

  const isNft = NFT_STANDARDS.includes(token.standard.name)
  const distributionAssetOptions = [{ label: 'USDC', value: DistributionAsset.USDC }]

  if (!isNft) {
    distributionAssetOptions.push({ label: symbol, value: DistributionAsset.Token })
  }

  const now = dayjs.utc()
  const [distributionDate, setDistributionDate] = useState<null | Dayjs>(
    defaults?.executeAt ? dayjs.utc(defaults.executeAt) : now,
  )
  const [snapshotDate, setSnapshotDate] = useState<null | Dayjs>(
    defaults?.snapshotUnixTimestamp ? dayjs.unix(defaults.snapshotUnixTimestamp).utc() : now,
  )
  const [distributionRate, setDistributionRate] = useState<number>(
    defaults?.distributionRate ? Number(defaults?.distributionRate) : 0,
  )
  const [totalAmountDistribution, setTotalAmountDistribution] = useState()
  const [totalProRata, setTotalProRata] = useState(0)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [isDistributionDateValid, setIsDistributionDateValid] = useState(false)

  const { mutate: distributionMutation } = useMutation(
    () => {
      let executeAt
      if (distributionDate?.isValid() && distributionDate.isAfter(now)) {
        executeAt = distributionDate.toISOString()
      }

      return createOperation(
        OperationType.Distribution,
        {
          distributionAsset,
          distributionRate,
          snapshotUnixTimestamp: snapshotDate?.isValid() ? snapshotDate.unix() : now.unix(),
          executeAt,
        },
        id,
      )
    },
    {
      onSuccess: () => {
        toast.success('Distribution request created.')
        onClose()
      },
      onError: onApiError,
    },
  )

  const { data, refetch, isLoading } = useQuery(
    ['get-token-distribution' + id],
    () =>
      getTokenDistribution(id, {
        distributionAsset: distributionAsset,
        distributionRate: distributionRate.toString(),
        snapshotUnixTimestamp: snapshotDate?.isValid() ? snapshotDate.unix() : now.unix(),
      }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  )

  const { data: payoutsData, isLoading: payoutsIsLoading } = useQuery(
    ['get-distribution-payouts', distributionId],
    () => getDistributionPayouts({ id: distributionId! }),
    {
      enabled: !!distributionId,
    },
  )

  const { totals, data: distribution } = data?.data || {}
  const { data: payouts } = payoutsData?.data?.distributionPayouts || {}

  const mode = defaults ? FormMode.Readonly : FormMode.Edit

  const handletypeDistributionChange = (event: SelectChangeEvent) => {
    setDistributionAsset(event.target.value as DistributionAsset)
  }

  const handleAmountDistributionChange = (values: any) => {
    setDistributionRate(values.value)
  }

  const blockExplorerColumn: ColDef<any> | undefined = useMemo(
    () =>
      distributionId
        ? ({
            headerName: 'Block explorer',
            field: 'explorerLink',
            sortable: true,
            filter: true,
            flex: 1,
            minWidth: 180,
            cellStyle: { padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' },
            cellRenderer: (params: any) => {
              if (params.data.explorerLink) {
                return (
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    justifyItems={'center'}
                    alignContent={'center'}
                    width={'100%'}
                  >
                    <Typography align="left" variant="caption">
                      <Link href={params.data.explorerLink.url} rel="noreferrer" target="_blank">
                        <img
                          alt={`${READABLE_PROTOCOL[protocol as ChainProtocol]} explorer logo`}
                          src={EXPLORER_LOGOS[protocol as ChainProtocol]}
                          width="18"
                        />
                        <span style={{ marginLeft: '6px' }}>View tx</span>
                      </Link>
                    </Typography>
                  </Box>
                )
              }
            },
          } as ColDef)
        : undefined,
    [distributionId, protocol],
  )

  const defaultColumnDefs: ColDef<any>[] = useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'holderName',
        sortable: true,
        filter: true,
        flex: 1,
        minWidth: 250,
      },
      {
        headerName: 'Address',
        field: 'holderAddress',
        sortable: true,
        filter: true,
        flex: 3,
        minWidth: 450,
      },
      {
        headerName: TABLE_HEADER_NAMES.common.balance,
        field: 'balance',
        sortable: true,
        filter: true,
        flex: 1,
        minWidth: 180,
        valueGetter: (params: any) => {
          return tokenBalanceFormatter(params.data.balance, decimals)
        },
      },
      {
        headerName: 'Amount',
        field: 'distributionAmount',
        sortable: true,
        filter: true,
        flex: 1,
        minWidth: 180,
        valueGetter: (params: any) => {
          const amountDecimals = distributionAsset === DistributionAsset.Token ? decimals : USDC_DECIMALS
          return tokenBalanceFormatter(params.data.distributionAmount, amountDecimals)
        },
      },
    ],
    [decimals, distributionAsset],
  )

  const columnDefs: ColDef<any>[] = useMemo(
    () => (blockExplorerColumn ? [...defaultColumnDefs, blockExplorerColumn] : [...defaultColumnDefs]),
    [defaultColumnDefs, blockExplorerColumn],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      rowData: distribution,
      domLayout: 'autoHeight',
      defaultColDef: { resizable: true },
    }),
    [columnDefs, distribution],
  )

  if (distributionId) {
    gridOptions.rowData = payouts
  }

  useEffect(() => {
    if (distributionRate > 0 && isDistributionDateValid) {
      setIsSubmitDisabled(false)
      !distributionId && refetch()
    } else {
      setIsSubmitDisabled(true)
    }
  }, [distributionRate, snapshotDate, refetch, distributionId, isDistributionDateValid])

  useEffect(() => {
    if (totals) {
      setTotalProRata(totals.balance)
      setTotalAmountDistribution(totals.distributionAmount)
    }
  }, [totals])

  useEffect(() => {
    if (distributionDate && snapshotDate) {
      setIsDistributionDateValid(!snapshotDate.isAfter(distributionDate))
    }
  }, [distributionDate, snapshotDate])

  return (
    <Wrapper>
      <Typography variant="h5" mb={4}>
        Distribution
      </Typography>
      <Box mb={4}>
        <Typography variant="inputLabelColored" id="type-distribution-label">
          Distribution asset
        </Typography>
        <Select
          sx={{ mt: 1 }}
          labelId="type-distribution-label"
          id="type-distribution"
          value={distributionAsset}
          label="Distribution asset"
          fullWidth
          variant="standard"
          onChange={handletypeDistributionChange}
          disabled={mode === FormMode.Readonly}
        >
          {distributionAssetOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box mb={4}>
        <DateTimePicker
          format={READABLE_DATE_FORMAT}
          value={distributionDate ?? dayjs.utc(distributionDate)}
          label="Distribution date (UTC)"
          minDateTime={dayjs.utc(createdAt)}
          onChange={(newDate) => {
            if (!newDate || !newDate?.isValid()) {
              return
            }
            setDistributionDate(newDate)
          }}
          slotProps={{ textField: { variant: 'standard', fullWidth: true, style: { marginBottom: 4 } } }}
          disabled={mode === FormMode.Readonly}
        />
        {!isDistributionDateValid && (
          <Typography variant="caption" color="error">
            Snapshot cannot be after distribution date
          </Typography>
        )}
      </Box>
      <Box mb={4}>
        <DateTimePicker
          format={READABLE_DATE_FORMAT}
          value={snapshotDate ?? dayjs.utc(snapshotDate)}
          label="Snapshot date (UTC)"
          minDateTime={dayjs.utc(createdAt)}
          onChange={(newDate) => {
            if (!newDate || !newDate?.isValid()) {
              return
            }
            setSnapshotDate(newDate)
          }}
          slotProps={{ textField: { variant: 'standard', fullWidth: true, style: { marginBottom: 4 } } }}
          disabled={mode === FormMode.Readonly}
        />
        {!isDistributionDateValid && (
          <Typography variant="caption" color="error">
            Snapshot cannot be after distribution date
          </Typography>
        )}
      </Box>
      <Box sx={{ mb: 4 }}>
        <NumericFormat
          min={0}
          id="amount-distribution"
          label="Price per fractional share"
          customInput={TextField}
          thousandSeparator={true}
          value={distributionRate}
          onValueChange={handleAmountDistributionChange}
          variant="standard"
          fullWidth
          allowLeadingZeros={false}
          disabled={mode === FormMode.Readonly}
        />
      </Box>
      <Typography variant="h5" mb={4}>
        Distribution table
      </Typography>
      {!distributionId && distributionRate > 0 && snapshotDate && (
        <>
          {isLoading && (
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <CircularProgress />
            </Box>
          )}
          {!isLoading && data && (
            <>
              <Box width={'100%'} className="ag-theme-material">
                <AgGridReact {...gridOptions}></AgGridReact>
              </Box>
              <Box mt={4} display="flex" justifyContent="space-between" borderTop={'1px solid #E0E2E4'}>
                <Typography variant="body1" padding={'14px 24px'}>
                  Total
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" mr={1} padding={'14px 24px'} minWidth={'174px'}>
                    {tokenBalanceFormatter(totalProRata.toString(), decimals)}
                  </Typography>
                  <Typography variant="body1" padding={'14px 24px'} minWidth={'174px'} align="right">
                    {currencyFormatter(totalAmountDistribution!, {
                      prefix: distributionAsset === DistributionAsset.Token ? symbol : 'USDC',
                      decimals: distributionAsset === DistributionAsset.Token ? decimals : USDC_DECIMALS,
                    })}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
      {distributionId && !snapshotDate?.isAfter(now) && (
        <>
          {payoutsIsLoading && (
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <CircularProgress />
            </Box>
          )}
          {!payoutsIsLoading && payouts && (
            <Box width={'100%'} className="ag-theme-material">
              <AgGridReact {...gridOptions}></AgGridReact>
            </Box>
          )}
        </>
      )}
      {distributionId && snapshotDate?.isAfter(now) && (
        <>
          <Typography variant="body2">
            The snapshot date is in the future and the distribution data will become available on{' '}
            {formatDate(snapshotDate.toString())}.
          </Typography>
          <Typography variant="body2" mt={3}>
            Please come back and confirm the data
          </Typography>
        </>
      )}
      {mode === FormMode.Edit && (
        <Box mt={4} display="flex" justifyContent="flex-end">
          <Button
            disabled={isSubmitDisabled}
            variant="contained"
            color="primary"
            onClick={() => {
              distributionMutation()
            }}
          >
            <Typography variant="body1">Submit</Typography>
          </Button>
        </Box>
      )}
    </Wrapper>
  )
}

export default DistributionForm
