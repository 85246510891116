import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getInternalOrganizations } from '../../../api/ace'
import { OrganizationCreateParams } from '../../../api/organizations'
import ArchaxLogo from '../../../assets/logo.svg'
import { formatDate } from '../../../util/formatters'
import { ORGANIZATION_LOGO_SIZE_LIMIT, ORGANIZATION_LOGO_SIZE_LIMIT_FORMATTED } from '../../../constants'
import { Autocomplete } from '../../../components/ui/Autocomplete'

export interface EditOrganizationDefaultValues {
  name: string
  internalOrganizationId: string
  logo: any
  logoHasTransparentBackground: boolean
  createdAt: string
}
interface OrganizationsFormProps {
  onSubmit: (data: any) => void
  mode: 'create' | 'edit'
  organizationId?: string
  defaultValues?: EditOrganizationDefaultValues
}

const validationSchema = Yup.object().shape({
  internalOrganization: Yup.object().required(),
  logo: Yup.mixed()
    .optional()
    .test('fileSize', 'File too large', (value: any) => {
      if (!value || !value[0]) return true
      if (typeof value === 'string') return true
      if (value && value[0] && value[0].size > 500000) {
        toast.error('File too large')
      }
      return value && value[0] && value[0].size <= 500000
    }),
  logoHasTransparentBackground: Yup.boolean().optional(),
})

const initialValues = {
  internalOrganization: null,
  logo: undefined,
  logoHasTransparentBackground: true,
}

function OrganizationsForm({ onSubmit, mode, defaultValues, organizationId }: OrganizationsFormProps) {
  const [previewLogo, setPreviewLogo] = useState(ArchaxLogo)
  const [internalOrganizationsOptions, setInternalOrganizationsOptions] = useState<
    { label: string; value: string | number }[]
  >([])
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    register,
    watch,
    setValue,
  } = useForm<OrganizationCreateParams & { internalOrganization: { label: string; value: number | string } | null }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues || initialValues,
    resolver: yupResolver(validationSchema),
  })

  useQuery(['get-internal-organizations'], () => getInternalOrganizations(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const internalOrganizations = data?.data?.data
      const selectInternalOrganizations = internalOrganizations?.map(
        (internalOrganization: { name: string; id: number }) => {
          return {
            label: internalOrganization.name,
            value: internalOrganization.id,
          }
        },
      )
      setInternalOrganizationsOptions(selectInternalOrganizations)
    },
  })

  const watchLogo = watch('logo')
  const watchBackground = watch('logoHasTransparentBackground')

  useEffect(() => {
    if (!watchLogo || !watchLogo[0]) {
      return setPreviewLogo(ArchaxLogo)
    }
    if (watchLogo && typeof watchLogo === 'string') {
      return setPreviewLogo(`/api/organizations/${organizationId}/logo/${watchLogo}`)
    }

    const newLogo = watchLogo[0]
    const fileSize = newLogo.size

    if (fileSize > ORGANIZATION_LOGO_SIZE_LIMIT) {
      toast.error(`Please choose a logo file that is ${ORGANIZATION_LOGO_SIZE_LIMIT_FORMATTED} or smaller`)
      setValue('logo', undefined)
      return
    }

    const reader = new FileReader()
    reader.onloadend = () => {
      setPreviewLogo(reader.result as string)
    }
    reader.readAsDataURL(newLogo)
  }, [watchLogo, organizationId, mode])

  useEffect(() => {
    if (defaultValues?.internalOrganizationId) {
      const organization = internalOrganizationsOptions.find(
        (option: any) => option.value === defaultValues?.internalOrganizationId,
      )
      if (organization) {
        setValue('internalOrganization', organization)
      }
    }
  }, [internalOrganizationsOptions])

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit({
          name: data.internalOrganization?.label,
          internalOrganizationId: data.internalOrganization?.value,
          logo: data.logo,
          logoHasTransparentBackground: data.logoHasTransparentBackground,
        })
      })}
    >
      <Autocomplete
        name="internalOrganization"
        label="Organization"
        control={control}
        options={internalOrganizationsOptions}
        disabled={mode === 'edit'}
      />
      <Typography align="left" variant="body1">
        Organization logotype (optional)
      </Typography>
      <Grid direction="row" container marginBottom={10}>
        <Grid item flex={1} alignContent="center" alignItems="center">
          <div
            style={{
              height: '136px',
              width: '136px',
              backgroundColor: watchBackground ? '#0D3169' : 'white',
              boxShadow: watchBackground ? 'none' : 'grey 0px 0px 10px 0px',
              borderRadius: 6,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '10px 10px 10px 0px',
            }}
          >
            <img
              alt="Organization logo"
              src={previewLogo}
              height="100px"
              width="100px"
              onError={(e) => {
                ;(e.target as HTMLImageElement).src = ArchaxLogo
              }}
              style={{
                objectFit: 'cover',
              }}
            />
          </div>
        </Grid>
        <Grid item flex={5} direction="column" container>
          <FormControlLabel
            name="logoHasTransparentBackground"
            control={<Switch {...register('logoHasTransparentBackground')} checked={watchBackground} />}
            label="Transparent background"
          />
          <Typography align="left" variant="caption" marginTop="16px" marginBottom="16px" color="#16191B4D">
            Square image at least 100x100px
            <br />
            JPG, PNG or SVG. Max size of 500K
          </Typography>
          <Button variant="contained" size="small" color="primary" component="label" sx={{ maxWidth: '150px' }}>
            <input hidden accept="image/*" type="file" {...register('logo')} />
            Choose file
          </Button>
        </Grid>
      </Grid>
      {mode === 'edit' && (
        <Grid container marginBottom={10} direction="column" spacing={1}>
          <Grid item flex={1} alignContent="center" alignItems="center">
            <Typography align="left" variant="subtitle3">
              Creation date
            </Typography>
          </Grid>
          <Grid item flex={5} container>
            <Typography align="left" variant="subtitle4">
              {defaultValues ? formatDate(defaultValues?.createdAt) : ''}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Button disabled={!isDirty || !isValid} type="submit" variant="contained" size="large" color="primary" fullWidth>
        Save
      </Button>
    </form>
  )
}

export default OrganizationsForm
