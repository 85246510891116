import {
  ChainProtocol,
  READABLE_PROTOCOL,
  READABLE_STANDARD,
  READABLE_TOKEN_TYPE,
  TABLE_HEADER_NAMES,
  Token,
  TokenStandardType,
  TokenStatus,
  TokenType,
} from '@archax/shared-types'
import ArchiveIcon from '@mui/icons-material/Archive'
import { Card, Switch, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Container } from '@mui/system'
import { ColDef, GridOptions, ICellRendererParams, RowClickedEvent, ValueFormatterParams } from 'ag-grid-community'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getTokens, getTokensUrl } from '../../api/tokens'
import { ServerSideGrid } from '../../components/ServerSideGrid'
import { substringFilterParams } from '../../util/common-grid-options'
import { readableTokenProtocol } from '../../util/token-utils'
import { useTranslation } from 'react-i18next'

function ListTokens() {
  const navigate = useNavigate()
  const [showArchived, setShowArchived] = useState(false)
  const { t } = useTranslation()

  const columnDefs: ColDef<Token>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: TABLE_HEADER_NAMES.common.name,
        minWidth: 300,
        sortable: true,
        sort: 'asc',
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'token_type',
        headerName: TABLE_HEADER_NAMES.common.type,
        valueGetter: (params) => READABLE_TOKEN_TYPE[params.data!.tokenType as TokenType],
        sortable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.values(TokenType),
          valueFormatter: (params: ValueFormatterParams<Token>) => READABLE_TOKEN_TYPE[params.value as TokenType],
        },
      },
      {
        field: 'symbol',
        headerName: TABLE_HEADER_NAMES.common.symbol,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'protocol',
        headerName: TABLE_HEADER_NAMES.common.protocol,
        valueGetter: (params) =>
          readableTokenProtocol(params.data!.standard.chain.protocol as ChainProtocol, params.data!.privacyEnabled),
        sortable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.values(ChainProtocol),
          valueFormatter: (params: ValueFormatterParams<Token>) => READABLE_PROTOCOL[params.value as ChainProtocol],
        },
      },
      {
        field: 'standard',
        headerName: TABLE_HEADER_NAMES.common.standard,
        valueGetter: (params) => READABLE_STANDARD[params.data!.standard.name as TokenStandardType],
        sortable: true,
        filter: true,
        filterParams: {
          values: Array.from(new Set(Object.values(TokenStandardType).map((standard) => READABLE_STANDARD[standard]))),
        },
      },
      {
        field: 'privacy_enabled',
        headerName: t('headers.common.privacy'),
        valueGetter: (params) => (params.data!.privacyEnabled ? t('general.true') : t('general.false')),
        sortable: true,
        filter: true,
        filterParams: {
          values: Array.from(new Set([t('general.true'), t('general.false')])),
        },
      },
    ],
    [],
  )

  if (showArchived) {
    columnDefs.push({
      field: 'status',
      headerName: '',
      cellRenderer: (params: ICellRendererParams<Token>) => {
        return params.data?.status === TokenStatus.Archived ? <ArchiveIcon /> : ''
      },
      maxWidth: 72,
      sortable: false,
      filter: false,
    })
  }

  const mutateFilters = useCallback(
    (filters: any) => {
      filters.archived = showArchived
      return filters
    },
    [showArchived],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      floatingFilter: true,
      defaultColDef: { flex: 1 },
      onRowClicked: (params: RowClickedEvent<Token>) => {
        navigate(`/tokens/${params.data!.id}`)
      },
      onGridReady: (params) => {
        params.api?.sizeColumnsToFit()
      },
    }),
    [navigate, columnDefs],
  )

  return (
    <Container data-testid="token-management" maxWidth="xl">
      <Card sx={{ p: 7 }}>
        <Typography data-testid="token-management__title" align="left" variant="h3">
          Token management
        </Typography>
        <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'} justifyContent={'space-between'}>
          <Typography align="left">Select the token you would like to manage</Typography>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Switch
              checked={showArchived}
              onChange={(event) => setShowArchived(event.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography sx={{ marginRight: 6 }} align="left" variant="body1">
              Include archived
            </Typography>
          </Box>
        </Box>

        <Box>
          <ServerSideGrid
            gridOptions={gridOptions}
            queryFn={getTokens}
            csvExportUrlGetter={getTokensUrl}
            mutateFilters={mutateFilters}
          />
        </Box>
      </Card>
    </Container>
  )
}

export default ListTokens
