import dayjs from 'dayjs'
import { createContext, Dispatch, ReactElement, useReducer } from 'react'
import { getConsolidatedCapTable, getConsolidatedCapTableUrl, getTokens, GetTokensParams } from '../../../api/tokens'
import { ConsolidatedCapTableEntry, Token } from '@archax/shared-types'
import { downloadCSVByPost } from '../../../api/axios'

type SelectedTokenType = {
  id: string
  name: string
}
type ConsolidatedCapTableParamsType = {
  selectedTokens: SelectedTokenType[]
  selectedDate?: Date
}

const DEFAULT_PAGINATION_PARAMS = {
  offset: 0,
  limit: 100,
}

const INITIAL_PAGINATION_PARAMS = {
  offset: 0,
  limit: 10,
}

export type State = {
  isLoading: boolean
  params: ConsolidatedCapTableParamsType
}

export enum ActionTypes {
  CHANGE_PARAMS,
}

export type Actions = {
  type: ActionTypes.CHANGE_PARAMS
  payload: Partial<ConsolidatedCapTableParamsType>
}

export const initialState: State = {
  isLoading: true,
  params: {
    selectedTokens: [],
  },
}

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.CHANGE_PARAMS:
      const newStateByParams = {
        ...state,
        params: {
          ...state.params,
          ...action.payload,
        },
      }

      return {
        ...newStateByParams,
      }
    default:
      return state
  }
}

export type PredefinedActions = {
  loadTokensOptions: (inputValue?: string) => Promise<Record<string, unknown>[]>
  loadConsolidatedData: () => Promise<ConsolidatedCapTableEntry[]>
  changeParams: (change: Partial<ConsolidatedCapTableParamsType>) => void
  exportCSV: () => Promise<void>
}

export type ConsolidatedCapTableContextType = {
  state: State
  actions: PredefinedActions
  dispatch: Dispatch<Actions>
}
export const ConsolidatedCapTableContext = createContext<ConsolidatedCapTableContextType>(
  {} as ConsolidatedCapTableContextType,
)

type Props = {
  children: React.ReactNode
}
function ConsolidatedCapTableProvider({ children }: Props): ReactElement {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  })

  return (
    <ConsolidatedCapTableContext.Provider
      value={{
        state,
        dispatch,
        actions: {
          loadTokensOptions: async (inputValue?: string) => {
            const {
              data: { data },
            } = await getTokens({
              ...(inputValue ? DEFAULT_PAGINATION_PARAMS : INITIAL_PAGINATION_PARAMS),
              name: inputValue,
            } as GetTokensParams)

            return data
              .filter((it: Token) => !state.params.selectedTokens.some((tk) => tk.name === it.name))
              .map((it: Token) => ({
                label: it.name,
                value: it.id,
              }))
          },
          changeParams: (change: Partial<ConsolidatedCapTableParamsType>) => {
            dispatch({
              type: ActionTypes.CHANGE_PARAMS,
              payload: change,
            })
          },
          loadConsolidatedData: async () => {
            if (state.params.selectedDate && state.params.selectedTokens.length) {
              const { data: responseData } = await getConsolidatedCapTable({
                tokenIds: state.params.selectedTokens.map((it) => it.id),
                timestamp: dayjs(state.params.selectedDate).unix(),
              })

              return responseData.data
            }
            return []
          },
          exportCSV: async () => {
            if (state.params.selectedDate && state.params.selectedTokens.length) {
              await downloadCSVByPost(getConsolidatedCapTableUrl(), {
                tokenIds: state.params.selectedTokens.map((it) => it.id),
                timestamp: dayjs(state.params.selectedDate).unix(),
              })
            }
          },
        },
      }}
    >
      {children}
    </ConsolidatedCapTableContext.Provider>
  )
}

export default ConsolidatedCapTableProvider
