import React from 'react'
import * as Sentry from '@sentry/react'
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'
const sentryDSN = process.env.REACT_APP_SENTRY_DSN

export const isSentryDefined = !!sentryDSN

export const initSentry = () => {
  if (!isSentryDefined) {
    return
  }

  Sentry.init({
    dsn: sentryDSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'abarch.io'],
  })
}
